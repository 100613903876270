<template>
  <div
    v-if="active"
    id="charterWindowAirport"
    ref="charterWindowAirport"
    key="charterWindowAirport"
    class="charter-search__window left-0 w-full"
  >
    <div
      v-click-outside="{ fn: active ? () => $emit('close') : () => null, ignore: '.charter-filter__introduction,.package-search,.charter-search__btn' }"
      class="charter-search__window-content overflow-auto lg:bg-white"
      :class="$isSol ? '!bg-light-turquoise lg:!bg-white' : '!bg-white-blue lg:!bg-white'"
    >
      <PackageSearchBarWindowHeader
        class="lg:mx-0 lg:mb-3"
        :select-error="selectAirportFirst ? $t('charterSelectAirportFirst') : null"
        :class="$isSol ? '!bg-light-turquoise pl-6 pr-2 -mx-3 sm:-mx-4 lg:mt-0 py-4' : 'pl-6 lg:pl-0 -mx-3 mb-0 bg-white sm:-mx-4 lg:mt-0'"
        @close="$emit('close')"
      >
        {{ $t('charterSelectDeparture') }}
      </PackageSearchBarWindowHeader>
      <div
        class="flex flex-wrap -mx-4 lg:mx-0"
        :style="{ rowGap: '15px'}"
        data-test-airports
      >
        <div
          v-if="featuredAirports.length"
          class="w-12/12 bg-white py-4 lg:py-0 lg:px-0 px-4"
          :class="!onlyFeaturedAirports ? singleColumnNormalAirports ? 'lg:w-3/12' : 'lg:w-3/12' : ''"
        >
          <ul class="w-full lg:h-100 lg:border-r">
            <li
              v-for="airport in featuredAirports"
              :key="airport.airport"
              class="lg:pr-4"
            >
              <button
                class="h6 py-2 px-4 md:px-4 mb-0 bg-transparent hover:bg-light-turquoise tracking-wider text-nowrap text-left rounded-[1.25rem] w-full"
                type="button"
                data-test-airport
                :class="{ [activeClass]: airport.airport === airportIsSelected }"
                @click="selectAirport(airport)"
              >
                {{ airport.airport_name }}
              </button>
            </li>
          </ul>
        </div>
        <div
          v-if="normalAirports.length"
          class="px-0 mx-0 overflow-hidden w-full"
          :class="singleColumnNormalAirports ? 'lg:w-3/12' : 'lg:w-9/12'"
        >
          <ul
            class="mb-0 lg:-mr-4"
            :class="singleColumnNormalAirports ? 'columns-2' : 'columns-2 md:columns-4'"
          >
            <li
              v-for="airport in normalAirports"
              :key="airport.airport"
              class="border-r mr-px-n1 px-4 flex lg:px-4"
            >
              <button
                class="h6 font-medium w-full lg:font-normal py-2 px-4 md:px-4 mb-0 bg-transparent hover:bg-light-turquoise tracking-wider text-nowrap text-center lg:text-left rounded-[1.25rem] relative grow"
                type="button"
                data-test-airport
                :class="{ [activeClass]: airport.airport === airportIsSelected }"
                @click="selectAirport(airport)"
              >
                <div class="inline-block lg:left-15 relative">
                  {{ airport.airport_name }}
                </div>
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'pinia'
import expirations from '@packages/cache/expirations'

import { ALL_AIRPORTS_CODE } from '@layers/web/constants/charter-packages.ts'

export default defineNuxtComponent({
  setup () {
    const setCookie = (name, val, opts) => {
      if (import.meta.browser) {
        const cookie = useCookie(name, { ...opts })
        if (val) {
          cookie.value = val
        }
      }
    }

    return {
      setCookie,
    }
  },

  props: {
    active: {
      type: Boolean,
      default: false,
    },

    selectedAirport: {
      type: Object,
      default: () => {},
    },

    selectAirportFirst: {
      type: Boolean,
      default: false,
    },

    prefilledSelectedAirport: {
      type: Object,
      default: () => {},
    },

    prefill: {
      type: Object,
      default: null,
    },

    airportOptions: {
      type: Array,
      default: null,
    },

    chokeInitiation: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['close', 'select'],

  data () {
    return {
      widthClass: 'xl',
    }
  },

  computed: {
    ...mapState(useCharterPackagesStore, {
      destinations: 'destinations',
      selectedAirportStore: 'selectedAirport',
      categoriesForAirport: 'categoriesForAirport',
    }),

    ...mapState(useRootStore, {
      airports: 'charterAirports',
    }),

    ...mapState(useSearchWidgetsStore, {
      stagedDestinationsL1: 'stagedDestinationsL1',
    }),

    activeClass () {
      if (this.$isSol) {
        return '!bg-vivid-turquoise'
      }

      return '!bg-medium-blue text-white'
    },

    filteredAirports () {
      const optionsProvided = (this.airportOptions || []).length > 0
      const list = optionsProvided ? this.airportOptions : (this.airports || [])
      return list.filter(a => a?.airport !== 'ZZZ')
    },

    normalAirports () {
      return this.filteredAirports.filter(a => !a?.featured_airport)
    },

    featuredAirports () {
      return this.filteredAirports.filter(a => !!a?.featured_airport)
    },

    onlyFeaturedAirports () {
      return this.featuredAirports.length > 0 && this.normalAirports.length === 0
    },

    singleColumnNormalAirports () {
      return this.normalAirports.length < 5
    },

    airportIsSelected () {
      return this.selectedAirport?.airport
    },
  },

  watch: {
    prefilledSelectedAirport (v) {
      if (!v || v?.airport === this.selectedAirport?.airport) {
        return
      }
      this.selectAirport(v, false)
    },

    selectedAirportStore (v) {
      if (!v || v?.airport === this.selectedAirport?.airport) {
        return
      }
      this.selectAirport(v, false)
    },
  },

  mounted () {
    if (!this.chokeInitiation) {
      let preSelectedAirport
      const localAirport = this.setCookie('charterAirport')

      if (this.prefilledSelectedAirport) {
        preSelectedAirport = this.prefilledSelectedAirport
      } else if (this.prefill && this.prefill?.selectedAirport) {
        preSelectedAirport = this.prefill?.selectedAirport || this.selectedAirport
      } else if (localAirport) {
        preSelectedAirport = this.filteredAirports.find(x => x?.airport === localAirport)
      } else if (this.selectedAirportStore && this.selectedAirportStore?.airport !== ALL_AIRPORTS_CODE) {
        preSelectedAirport = this.selectedAirportStore
      }

      if (preSelectedAirport) {
        this.selectAirport(preSelectedAirport, false)
      }
    }
  },

  methods: {
    ...mapActions(useSearchWidgetsStore, {
      setStagedAirport: 'SET_STAGED_AIRPORT',
    }),

    ...mapActions(useCharterPackagesStore, {
      setAirport: 'SET_SELECTED_AIRPORT',
    }),

    selectAirport (airport, fullUpdate = true) {
      this.$emit('select', [airport, fullUpdate])

      if (airport?.airport !== this.selectedAirportStore?.airport) {
        this.setStagedAirport(airport)
        this.setAirport(airport)
      }

      if (airport?.airport === ALL_AIRPORTS_CODE) {
        return
      }
      try {
        window.localStorage.setItem('charterAirport', JSON.stringify(airport))
      } catch {
        // Storage Exceeded
      }
      this.setCookie(
        'charterAirport',
        airport,
        {
          maxAge: expirations.longDuration,
          sameSite: 'lax',
          path: '/',
        }
      )
    },
  },
})
</script>

<style lang="scss">
#charterWindowAirport {
  &.sm .row {
    & > div:first-of-type,
    & > div:last-of-type {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }

  &.md .row {
    & > div:first-of-type {
      flex: 1 0 33.33333;
      max-width: 33.33333%;
    }
    & > div:last-of-type {
      flex: 1 0 66.66666%;
      max-width: 66.66666%;
    }
  }

  &.lg .row,
  &.xl .row {
    & > div:first-of-type {
      flex: 1 0 25%;
      max-width: 25%;
    }
    & > div:last-of-type {
      flex: 1 0 75%;
      max-width: 75%;
    }
  }
}
</style>
